import React from 'react';
import { Layout } from 'src/components/Layout';
import { SignUpPage } from 'src/components/SignUpPage/component';

export default class SignUp extends React.Component {
	render() {
		return (
			<Layout suppressVerificationReminder={true}>
				<SignUpPage controller={this} headingText="Create an Xceptional Account" />
			</Layout>
		);
	}
}
